@import '~antd/dist/antd.less';

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-family: 'Montserrat', sans-serif;

@primary-color: #ff4d4f;

@font-size-base: 16px;
@font-size-sm: 14px;

@height-base: 40px;
@height-lg: 48px;
@height-sm: 32px;

@layout-header-background: @primary-color;

html {
  scroll-behavior: smooth;
  scroll-padding-top: @layout-header-height;
}
