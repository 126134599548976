@import '../index.less';

.collapsible-menu__drawer .ant-drawer-close {
  color: @white;
  font-size: @font-size-base * 1.125;
  margin-right: 10px;
  padding: 0 !important;
}

.collapsible-menu__trigger {
  color: @white !important;
  font-size: @font-size-base * 1.125;
}
