@import '../index.less';

.produto__container {
  border: 1px solid @border-color-base;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;

  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}

.produto__info {
  flex-basis: 60%;

  display: flex;
  flex-direction: column;
}

.produto__info__descricao {
  font-size: 0.85rem;
}

.produto__info__detalhe {
  flex-grow: 2;
  font-size: 0.75rem;
}

.produto__info__preco {
  font-size: 0.75rem;

  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.produto__info__preco__tag {
  border-radius: 0.5rem !important;
  font-size: 0.65rem !important;
}

.produto__imagem {
  flex-basis: 40%;
}

.produto__imagem > img {
  border-radius: 0.5rem;
  width: 100%;
}

@media (min-width: 480px) {
  .produto__info__descricao {
    font-size: 1rem;
  }

  .produto__info__detalhe {
    font-size: 0.85rem;
  }

  .produto__info__preco {
    font-size: 0.85rem;
  }
}

@media (min-width: 576px) {
  .produto__info {
    flex-basis: 70%;
  }

  .produto__imagem {
    flex-basis: 30%;
  }

  .produto__info__descricao {
    font-size: 1.125rem;
  }

  .produto__info__preco__tag {
    font-size: 0.75rem !important;
  }
}

@media (min-width: 768px) {
  .produto__container {
    padding: 0.75rem;
  }

  .produto__info {
    flex-basis: 75%;
  }

  .produto__info__descricao {
    font-size: 1.25rem;
  }

  .produto__info__detalhe {
    font-size: 1rem;
  }

  .produto__info__preco__tag {
    font-size: 0.85rem !important;
  }

  .produto__imagem {
    flex-basis: 25%;
  }
}

@media (min-width: 1600px) {
  .produto__info__preco {
    font-size: 1rem;
  }
}
