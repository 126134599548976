.produto-imagem-padrao__container {
  background-color: rgb(204, 204, 204);
  color: #fff;
  padding: 1rem;

  border-radius: 0.5rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
