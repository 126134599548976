@import '../index.less';

.cardapio__content {
  background-color: @white;
  padding: @padding-md @padding-md 0;
  min-height: calc(100vh - @layout-header-height) !important;
}

.cardapio__header {
  padding: 0 @padding-md !important;
}
