@import '../index.less';

.menu__container {
  background-color: @primary-color !important;
  border: none !important;
  color: @white !important;
}

.menu__container > .ant-menu-item:hover {
  background-color: @primary-1 !important;
}
