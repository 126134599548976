@import '../index.less';

.full-page-loader__container {
  background: @body-background;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
